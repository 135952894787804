<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="80vw"
            persistent
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    View Transcript
                </v-btn>
            </template>
            
            <v-card
                v-if="dialog"
                height="90vh"
                style="position:relative;" 
            >
                <v-card-title class="text-h5 grey lighten-2">
                    Transcript
                </v-card-title>

                <iframe 
                    ref="pdf" 
                    style="width:100%; height:85%" 
                    :src="src"
                    @load="pdfLoaded"
                ></iframe> 

                <v-overlay
                    absolute
                    :value="overlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
                
                
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="close"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        studentId: Number
    },
    data: function () {
        return {
            dialog: false,
            overlay: false,
            src: null,
        }
    },

    watch: {
        dialog (val) {
            if(val) this.initialize();
        }
    },

    methods: {
        initialize () {
            this.overlay = true;
            console.log('initializing...');
            this.src = `${process.env.VUE_APP_API_URI}/api/student-transcript?studentId=${this.studentId}`;
        },

        close () {
            this.dialog = false;
        },

        pdfLoaded () {
            this.overlay = false;
            console.log('pdf loaded');
        }
    }
}
</script>