<template>
    <v-card
        ref="form"
        style="overflow:hidden"
    >
        <v-card-title style="position:relative">
            <span class="headline">{{ formTitle }}</span>
            <v-btn
                fab
                text
                small
                absolute
                right
                top
                class="mr-12"
                @click="registrationForm"
            >
                <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
                color="red"
                absolute
                top
                right
                text
                fab
                small
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
            <v-container>
                <v-row>
                    <v-col cols="4">
                        <v-row>
                            <v-col cols="12">
                                <v-card
                                    flat
                                    class="mx-auto"
                                    width="200"
                                    
                                >
                                    <div class="d-flex" style="width:40px">
                                        <v-file-input
                                            :value="pictureFile"
                                            @change="uploadPicture"
                                            placeholder="Edit Picture"
                                            append-icon="mdi-camera"
                                            prepend-icon="mdi-camera"
                                            hide-details="auto"
                                            outlined
                                            dense
                                            :disabled="!admin"
                                            :hide-input="true"
                                        ></v-file-input>
                                        <v-btn icon>
                                            <v-icon
                                                @click="confirmDeletePicture"
                                                v-if="admin || editPermission"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </div>    
                                

                                    <v-img
                                        :src="studentRecord.picture"
                                        width="200"
                                        height="200"
                                        contain
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>

                                    <v-overlay
                                        absolute
                                        :value="overlayPicture"
                                        opacity="0.60"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            size="64"
                                            color="primary"
                                        ></v-progress-circular>
                                    </v-overlay>

                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row v-if="pictureFile">
                            <v-col>
                                <v-card
                                    class="mx-auto d-flex justify-center align-center"
                                    width="200"
                                >
                                    <v-progress-linear
                                        v-model="progress"
                                        color="blue-grey"
                                        height="25"

                                        reactive
                                    >
                                        <strong>{{ progress }} %</strong>
                                    </v-progress-linear>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row v-if="pictureUploadMessage">
                            <v-alert  border="left" color="primary" dark>
                                {{ pictureUploadMessage}}
                            </v-alert>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-card
                                    class="mx-auto"
                                    width="200"
                                    flat
                                >
                                    <div class="d-flex align-center">
                                        <v-text-field
                                            v-model="studentRecord.student_id"
                                            label="Student ID"
                                            :disabled="lockStudentId"
                                            :filled="lockStudentId"
                                            outlined
                                            dense
                                            class="mt-4 record-summary"
                                            hide-details="auto"
                                        ></v-text-field>
                                        <v-btn
                                            icon
                                            small
                                            :disabled="!admin"
                                            @click="lockStudentId=!lockStudentId"
                                        >
                                            <v-icon>{{ lockIconStudentId }}</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row class="mt-4">
                            <v-col cols="12">
                                <v-card
                                    class="mx-auto"
                                    width="200"
                                    flat
                                >
                                    <div class="d-flex align-center">
                                        <v-text-field
                                            v-model="studentRecord.serial_number"
                                            label="Serial Number"
                                            hide-details="auto"
                                            :disabled="lockSerialNumber"
                                            outlined
                                            :filled="lockSerialNumber"
                                            dense
                                        ></v-text-field>
                                        <v-btn
                                            icon
                                            small
                                            :disabled="!admin"
                                            @click="lockSerialNumber=!lockSerialNumber"
                                        >
                                            <v-icon>{{ lockIcon }}</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row class="mt-4">
                            <v-col cols="12">
                                <v-card
                                    class="mx-auto"
                                    width="200"
                                    flat
                                >
                                    <div class="d-flex align-center">
                                        <Transcript
                                            :studentId="studentRecord.student_id"
                                        ></Transcript>
                                    </div> 
                            
                                </v-card>
                            </v-col>
                        </v-row> 

                    </v-col>

                    <v-col cols="8">
                         <v-card
                            width="500"
                            flat
                            tile
                            style="border-left: thin solid rgba(0, 0, 0, 0.12)"
                            class=""
                        >
                            <v-tabs
                                v-model="tab"
                                fixed-tabs
                            >

                                <v-tab
                                    v-for="(item, i) in tabItems"
                                    :key="i"
                                    :disabled="item.disabled"
                                >
                                    {{ item.section }}
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <DataGeneral
                                    v-bind:formClasses="formClasses"
                                    v-bind:validate="validate"
                                    v-on:update-students="updateStudents"
                                    v-on:enable-tabs="enableTabs"
                                    v-on:save-status="saveStatus"
                                    ref="dataGeneral"
                                ></DataGeneral>
                                <data-personal
                                    v-bind:record="dataPersonal"
                                    v-bind:ethnicGroups="ethnicGroups"
                                    v-bind:religiousGroups="religiousGroups"
                                ></data-personal>
                                <data-medical
                                    v-bind:record="dataMedical"
                                ></data-medical>
                                <data-family
                                    v-bind:records="dataFamily"
                                ></data-family>
                            </v-tabs-items>

                            <v-card-actions class="pr-4 mt-4">
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mr-4"
                                    color="red"
                                    text
                                    @click="close"
                                >
                                    Close Record
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    outlined
                                    @click="save"
                                >
                                    Save Record
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </v-card-text>

        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"
            absolute
        >
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="reportViewer"
            max-width="90vw"
            @click:outside="closeReport"
        >
            <v-card
               height="90vh"
               style="position:relative;"
            >
                <iframe
                    ref="pdf"
                    style="width:100%; height:100%"
                    class="report-viewer"
                    :src="src"
                ></iframe>
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackBar.display"
            :timeout="3000"
            :color="snackBar.color"
        >
            {{ snackBar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    text
                    @click="snackBar.display = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog
            v-model="dialogDelete"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h6 pl-4 white--text primary">
                    Delete Picture?
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogDelete = false">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="text-h6 font-weight-regular pa-4">
                    This action can't be undone.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pa-4"> 
                    <v-spacer></v-spacer>
                    <v-btn class="mr-4" depressed outlined @click="dialogDelete = false" small>Cancel</v-btn>
                    <v-btn color="primary" @click="deletePicture" small>Yes Delete</v-btn>
                </v-card-actions>
            </v-card>   
        </v-dialog>

        

    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DataGeneral from './StudentsEditDataGeneral.vue';
import DataPersonal from './StudentsEditDataPersonal.vue';
import DataMedical from './studentsEditDataMedical.vue';
import DataFamily from './studentsEditDataFamily.vue';
import UploadService from "../services/UploadFileService";
import Transcript from "./Transcript.vue";

export default {
    components: {
        DataGeneral,
        DataPersonal,
        DataMedical,
        DataFamily,
        Transcript,
    },

    props:{
        formTitle: {
            type: String,
            default: '',
        },

        formClasses: {
            type: Array,
            default: function () {
                return []
            }
        }
    },

    created: function () {
        this.initialize();
    },

    watch: {
       //
    },

    computed: {
        ...mapGetters({
            studentRecord: 'students/getEditedStudent',
            user: 'auth/getUser',
        }),

        lockIcon () {
            if(this.lockSerialNumber) return "mdi-lock";
            return "mdi-lock-open";
        },

        lockIconStudentId () {
            if(this.lockStudentId) return "mdi-lock";
            return "mdi-lock-open";
        },

        admin () {
            let user = JSON.parse(this.user);
            if(user.employee_id === 'Admin') return true;
            return false;
        }


    },

    data: () => ({
        tab: null,
        tabItems: [
            { section: 'General Info', disabled: false},
            { section: 'Personal Info', disabled: false},
            { section: 'Medical Info', disabled: false},
            { section:'Family Info', disabled: false}
        ],
        errorMessage: '',
        saved: false,
        error: false,
        overlay: false,
        dataPersonal: {},
        dataMedical: {},
        dataFamily: {},
        religiousGroups: [],
        ethnicGroups: [],
        reportViewer: false,
        reportViewerOverlay: false,
        src: '',
        picture: false,
        pictureSrc: '',
        pictureFile: null,
        pictureUploadMessage: null,
        progress: 100,
        lazySrc: require('../assets/icons/student.svg'),
        editPicture: true,
        studentId: null,
        validate: false,
        snackBar: {
            text: "",
            color: "primary",
            display: false,
        },
        lockSerialNumber: true,
        lockStudentId: true,
        overlayPicture: false,
        dialogDelete: false,
    }),

    methods: {
        ...mapActions({
            getDataPersonal: 'students/getDataPersonal',
            getDataMedical: 'students/getDataMedical',
            getDataFamily: 'students/getDataFamily',
            getReligiousGroups: 'students/getReligiousGroups',
            getEthnicGroups: 'students/getEthnicGroups',
            postStudent: 'students/postStudent',
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
        }),

        async initialize () {
            if(!this.studentRecord.student_id){
                this.studentRecord.picture = require("../assets/icons/student2.svg")
                this.tabItems.forEach(item => {
                    if(item.section != 'General Info') item.disabled = true;
                })
                return;
            }

            this.studentId = this.studentRecord.student_id;
            this.getData();
           
        },

        enableTabs () {
            this.tabItems.forEach(item => {
                item.disabled = false;
            })
            this.overlay = false;
        },

        async getData () {
            const promiseDataPersonal = this.getDataPersonal();
            const promiseDataMedical = this.getDataMedical();
            const promiseDataFamily = this.getDataFamily();
            const promiseReligiousGroups = this.getReligiousGroups();
            const promiseEthnicGroups = this.getEthnicGroups();

            try {
                const [
                    { data: dataPersonal },
                    { data: dataMedical },
                    { data: dataFamily },
                    { data: religiousGroups },
                    {data: ethnicGroups}
                ] = await Promise.all([
                    promiseDataPersonal,
                    promiseDataMedical,
                    promiseDataFamily,
                    promiseReligiousGroups,
                    promiseEthnicGroups
                ])

                this.dataPersonal = dataPersonal;
                // this.loadPicture(dataPersonal);
                this.dataMedical = dataMedical;
                this.dataFamily = Array.isArray(dataFamily) ? {} : dataFamily;

                this.religiousGroups = religiousGroups.map(group => {
                    return {
                        id: group.id,
                        title: group.grouping
                    }
                })

                this.ethnicGroups = ethnicGroups.map(group => {
                    return {
                        id: group.id,
                        title: group.grouping
                    }
                })

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }



        },

        save () {
            this.lockStudentId = true;
            this.lockSerialNumber = true;
            this.overlay = true;
            this.validate = true;
        },

        saveStatus ({ type, text}) {
            this.validate = false;
            this.overlay = false;
            if(type == 'success'){
                this.snackBar.text = text;
                this.snackBar.color = "primary";
                this.snackBar.display = true;
                return;
            }

            if(type == 'error'){
                this.snackBar.text = text;
                this.snackBar.color = "red";
                this.snackBar.display = true;
                return;
            }
        },

        addNewStudent () {
            console.log('add new student');
            this.overlay = true;
            // console.log(`Validate ${this.$refs.dataGeneral.validate()}`)
            if(!this.$refs.dataGeneral.validate()) this.overlay = false;
            else{
                this.$refs.dataGeneral.update();
            }
        },

        close () {
            this.$emit("close");
            this.tab = null;
            this.error = false;
            this.saved = false;
            this.date = null;
            // Object.keys(this.studentRecord).forEach(f => {
            //     if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
            //         this.$refs[f].reset()
            //     }
            // })
        },

        updateStudents () {
            this.studentRecord.student_id = this.studentRecord.id;
            this.overlay = false;
            this.$emit('update-students');
        },

        registrationForm () {
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.$nextTick( function () {
                //console.log(this.$refs.pdf);
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })
            this.src = process.env.VUE_APP_API_URI + "/api/registration-form/"
            + this.studentRecord.student_id;
        },

        closeReport () {
            this.src = null;
        },

        async uploadPicture (file) {
            if(!file) return;
            this.overlayPicture = true;
            this.progress = 0;
            this.pictureFile = file;

            if(!this.pictureFile){
                return;
            }
            
            this.pictureUploadMessage = null;

            try {
                const { data } = await UploadService.upload(
                this.pictureFile,
                this.studentRecord.student_id,
                'picture',
                (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                    }
                )
                this.studentRecord.picture = data.picture;
                this.$emit('update-students');

            } catch (error) {
               if(error.response) console.log(error.response);
               else console.log(error);
            }
            this.pictureFile = null;
            this.overlayPicture = false;

        },

        confirmDeletePicture () {
            this.dialogDelete = true;
        },

        async deletePicture () {
            if(this.studentRecord.picture == null) return;
            this.overlay = true;
            this.dialogDelete = false;
            try {
                await UploadService.delete(this.studentRecord.student_id, this.studentRecord.picture, 'picture');
                this.studentRecord.picture = require("../assets/icons/student2.svg");
                this.$emit('update-students');
                this.dialogDelete = false;
            } catch (error) {
                if(error.response) console.log(error.response);
            }
            this.overlay = false;
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-tab{
        max-width: 120px;
    }

    ::v-deep .v-slide-group__prev{
        display: none;
    }

    ::v-deep .v-btn--fab.v-size--small.v-btn--absolute.v-btn--top{
        top: 4px;
        right: 4px;
    }

    ::v-deep .record-summary input{
        font-weight: bold;
    }

    ::v-deep .theme--light.v-file-input .v-file-input__text--placeholder{
        font-size: 0.8rem;
    }
</style>